import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const employerState = atom({
  key: 'employerState',
  default: null,
});
//TODO - Clean - Endpoints should return a typed employer, maybe a specific DTO from the API
//               Because why use typescript but then only take advantage of 'any'

const endpoint = `${APIURL}/Employer`;

const employerService = {
  async usageReport(month: number, year: number) {
    return axios.get(`${endpoint}/UsageReport/${month}/${year}`);
  },
  async Overview() {
    return axios.get(`${endpoint}/Overview`);
  },
  async GetEmployer() {
    return axios.get(`${endpoint}/GetEmployer`);
  },
  async GetEmployers() {
    return axios.get(`${endpoint}/GetEmployers`);
  },
  async GetNumber(name: string) {
    return axios.get(`${endpoint}/` + name).then((response) => {
      return response.data.number;
    });
  },
  // MembersCaregiverEnergy
  async MembersScores() {
    return axios.get(`${endpoint}/MembersScores`);
  },
  // DropdownOptions
  async DropdownOptions() {
    return axios.get(`${endpoint}/DropdownOptions`);
  },
  // AcceptTerms
  async AcceptTerms() {
    const res = await fetch('https://jsonip.com/').then(function (response) {
      return response.json();
    });
    return axios.post(`${endpoint}/AcceptTerms`, {
      IpAddress: res.ip,
    });
  },
  async UpdateBirthdayReward(birthdayReward: number | undefined) {
    return axios.post(`${endpoint}/UpdateBirthdayReward`, birthdayReward);
  }
};

export default employerService;
