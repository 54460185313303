import { IonButton, IonContent, IonModal } from '@ionic/react';
import React, { useState } from 'react';
import employerService from '../Shared/services/EmployerService';

interface Props {
  employer: any;
}

export default function AcceptTerms(props: Props) {
  const [showTerms, setShowTerms] = useState(!props.employer.acceptedTerms);
  const [canDismiss, setCanDismiss] = useState(false);

  function acceptTerms() {
    setCanDismiss(true);
    employerService.AcceptTerms();
    setTimeout(() => {
      setShowTerms(false);
    }, 300);
  }

  return (
    <>
      <IonModal
        isOpen={showTerms}
        canDismiss={canDismiss}
        className='mx-modal'
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setCanDismiss(true);
            setTimeout(() => {
              setShowTerms(false);
            }, 300);
          }
        }}
      >
        <IonContent>
          <div style={{ padding: 40, marginBottom: 30 }}>
            <h5>Welcome to Maxwell!</h5>
            <p>
              We are so happy you made it. As your one of your team’s culture champions with admin
              abilities, you have access to the Maxwell HR View. Here you can view key data on your
              team’s usage and wellbeing, update talent segments, add or remove members, and make
              people’s day with the Admin Spot Bonus feature.
            </p>
            <h5>One Last Step</h5>
            <p>
              Before diving in, we have the obligatory terms & agreements. By selecting the button
              below, you are agreeing to the Issuing Bank Terms set forth by Stripe along with the
              following terms:
            </p>
            <a target='_blank' rel='noreferrer' href='https://stripe.com/legal/connect-account'>
              Stripe Connected Account Agreement{' '}
            </a>
            <br />
            <a target='_blank' rel='noreferrer' href='https://stripe.com/legal/ssa#services-terms'>
              Stripe Issuing Account Holder Terms
            </a>{' '}
            <br />
            <a
              target='_blank'
              rel='noreferrer'
              href='https://stripe.com/issuing/celtic/apple-payment-platform-program-manager-customer-terms-and-conditions/legal'
            >
              Apple Pay Terms & Conditions{' '}
            </a>{' '}
            <br />
            <a target='_blank' rel='noreferrer' href='https://stripe.com/legal/celtic-spend-card'>
              Issuing Bank Terms{' '}
            </a>{' '}
            <br />
            <a
              target='_blank'
              rel='noreferrer'
              href='https://stripe.com/legal/issuing-offer-document-fees'
            >
              Commercial Financing Disclosure
            </a>{' '}
            <br /> <br />
            <p>
              And with that, the world of delivering balance and joy to your team awaits. If you
              need anything, reach out to stephen.enke@maxwell.app for personalized support (from a
              real human).{' '}
            </p>
            <br />
          </div>
          <IonButton
            onClick={() => {
              acceptTerms();
            }}
            color='dark'
            expand='block'
          >
            Agree & Continue
          </IonButton>

          <div style={{ padding: 40, marginBottom: 30, fontSize: 'small' }}>
            Maxwell Card Visa® Commercial credit cards are issued by Celtic Bank, MemberFDIC and
            serviced by Stripe, Inc. and its affiliate Stripe Servicing, Inc.
          </div>
        </IonContent>
      </IonModal>
    </>
  );
}
