import React, { useState, useEffect } from 'react';
import { DatetimeChangeEventDetail, DatetimeCustomEvent, IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
import moment from 'moment';

interface DateSelectorProps {
    id: string;
    value: Date | null | undefined;
    onChange: (d: Date | null) => void; 
}

const DateSelector = (props: DateSelectorProps) => {
    const handleChange = (event: DatetimeCustomEvent) => {
        const newDate = event.detail.value ? new Date(event.detail.value.toString()) : null;
        props.onChange(newDate);
    }
    return (
        <>
            <IonDatetimeButton datetime={props.id}></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
                <IonDatetime id={props.id} 
                             presentation='date' 
                             onIonChange={handleChange}
                             className='mx-border'
                             value={props.value ? moment(props.value).toISOString() : null}>
                </IonDatetime>
            </IonModal>
        </>
    )
};

export default DateSelector;