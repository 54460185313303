import { atom } from 'recoil';
import { AnnouncementAdminDto } from '../../Shared/services/AnnouncementAdminService';

export const activeAnnouncementDataState = atom<AnnouncementAdminDto[]>({
  key: 'activeAnnouncementData',
  default: [],
});

export const pastAnnouncementDataState = atom<AnnouncementAdminDto[]>({
  key: 'pastAnnouncementData',
  default: [],
});

export const newAnnouncementDataState = atom<AnnouncementAdminDto | null>({
  key: 'newAnnouncementData',
  default: null,
});

export const editAnnouncementDataState = atom<AnnouncementAdminDto | null>({
  key: 'editAnnouncementData',
  default: null,
});
