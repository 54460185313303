import React, { useState, useEffect } from 'react';
import teamService from '../../Shared/services/TeamService';
import { TeamQuery } from '../../Team/Team_Page/atoms';
import Select from 'react-select';
import flatMap from 'lodash/flatMap';

interface MemberIdSelectorProps {
  onMemberIdChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface MemberOption {
  value: number;
  label: string;
}

const MemberIdSelector = (props: MemberIdSelectorProps) => {
  const [teamOptions, setTeamOptions] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  useEffect(() => {
    const query: TeamQuery = {
      page: 0,
      pageSize: 1000,
    };

    const options: MemberOption[] = [];

    teamService
      .allTeamMembers(query)
      .then((res) => {
        const wholeTeam = flatMap(res.data.members).filter((t: any) => {
          return t.name != null && t.name !== '' && t.name !== ' ';
        });
        wholeTeam.map((t: any) => {
          options.push({ value: t.id, label: t.name });
        });
        setTeamOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSelectedMemberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(parseInt(event.target.value));
    props.onMemberIdChange(event);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
      }}
    >
      <select
        required={false}
        value={selectedId || ''}
        onChange={handleSelectedMemberChange}
        className='mx-border'
        style={{
          padding: 8,
        }}
      >
        <option value='' disabled>
          Select SentByMember...
        </option>
        {teamOptions.map((opt: MemberOption) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MemberIdSelector;
