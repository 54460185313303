import React, { useEffect, useState } from 'react';
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonIcon,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { createOutline, archiveOutline, checkboxOutline, checkbox, trash } from 'ionicons/icons';
import { AnnouncementAdminDto } from '../../../Shared/services/AnnouncementAdminService';
import moment from 'moment';
import ImageService from '../../../Shared/services/ImageService';
import ReadMoreText from '../../../components/ReadMoreText';

export const AnnouncementComponent: React.FC<{
  announcement: AnnouncementAdminDto;
  isActive: boolean;
  isPresent: boolean;
  onEdit: (id: number) => void;
  onApprove?: (id: number) => void;
  onDelete?: (id: number) => void;
}> = ({ announcement, isActive, isPresent, onEdit, onApprove, onDelete }) => {
  const [pastAnnouncement, setPastAnnouncement] = useState<boolean>(false);
  useEffect(() => {
    //calculate if this announce is in the past
    const now = new Date().setHours(0, 0, 0, 0); //set to midnight to ignore time
    if (!announcement.whenExpires){
      setPastAnnouncement(false);
    } else {
      const expiresDate = new Date(announcement.whenExpires).setHours(0, 0, 0, 0); //Set to midnight to ignore time
      setPastAnnouncement(expiresDate <= now);
    }
  }, []);

  function renderStatus(announcementStatus: number){
    switch(announcementStatus){
      case 1:
        return 'Approved'
      case 2:
        return 'Past'
      case 3:
        return 'Ready'
      default:
        return 'Pending';
    }
  }

  return (
    <IonCard style={{ margin: '10px' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '120px 1fr auto',
          gridTemplateRows: 'auto 1fr',
          gridTemplateAreas: `
          "image header actions"
          "image content content"
        `,
          height: '120px',
        }}
      >
        <IonImg
          src={
            announcement.picture ? ImageService.Url(announcement.picture) : '/assets/icon/icon.png'
          }
          alt={announcement.title}
          style={{
            gridArea: 'image',
            width: '120px',
            height: '120px',
            objectFit: 'cover',
          }}
        />
        <IonCardHeader
          style={{
            gridArea: 'header',
            padding: '8px 12px 0',
            alignSelf: 'start',
          }}
        >
          <IonCardTitle
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              marginBottom: '4px',
            }}
          >
            {announcement.title}
          </IonCardTitle>
          <IonCardSubtitle
            style={{
              fontSize: '12px',
              marginBottom: '4px',
            }}
          >
            {announcement.whenLive && (
              <div>Goes Live: {moment(announcement.whenLive).format('YYYY-MM-DD')}</div>
            )}
            {announcement.whenExpires && (
              <div>Expires: {moment(announcement.whenExpires).format('YYYY-MM-DD')}</div>
            )}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent
          style={{
            gridArea: 'content',
            padding: '0 12px 8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            fontSize: '14px',
          }}
        >
          {/* TODO - Once we have ReadMoreText working 'as indended', use it below
            <ReadMoreText
            id='announcement-body'
            text={announcement.body} /> */}
          <p>{announcement.body}</p>
          { !pastAnnouncement && //if announcement is in the past, we don't care about the status
            <p>Status: {renderStatus(announcement.status)}</p>
          }
        </IonCardContent>
        <div
          style={{
            gridArea: 'actions',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '4px',
            gap: '4px',
          }}
        >
          {onApprove && isActive === false ? (
            <IonButton
              color='dark'
              size='small'
              onClick={() => onApprove(announcement.id)}
              style={{
                '--padding-start': '4px',
                '--padding-end': '4px',
              }}
            >
              <IonIcon 
                icon={checkboxOutline}
                style={{
                  padding: '4px'
                }}/>
              Approve
            </IonButton>
          ) : (
            <IonButton
              color='dark'
              size='small'
              onClick={() => console.log('Announcement Already Approved!')}
              style={{
                '--padding-start': '4px',
                '--padding-end': '4px',
              }}
              disabled={true}
            >
              <IonIcon 
                icon={checkbox}
                style={{
                  padding: '4px'
                }}/>
              Approved
            </IonButton>
          )}
          { isPresent && (
          <IonButton
            color='dark'
            size='small'
            onClick={() => onEdit(announcement.id)}
            style={{
              '--padding-start': '4px',
              '--padding-end': '4px',
            }}
          >
            <IonIcon 
              icon={createOutline}
              style={{
                padding: '4px'
              }}/>
            Edit
          </IonButton>
          )}
        </div>
        { !isActive && onDelete && (
          <div
            style={{
              gridArea: 'actions',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              padding: '4px',
              gap: '4px',
            }}>

          <IonButton
            color='dark'
            size='small'
            onClick={() => onDelete(announcement.id)}
            style={{
              '--padding-start': '4px',
              '--padding-end': '4px',
            }}
          >
            <IonIcon 
              icon={trash}
              style={{
                padding: '4px'
              }}/>
            Delete
          </IonButton>
          </div>
        )
        }
      </div>
    </IonCard>
  );
};
//TODO - get the rest of the announcement fields in here, should this be editable?
