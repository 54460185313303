import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import NavBar from '../../Shared/NavBar';
import SideBar from '../../Shared/SideBar';
import { useRecoilState } from 'recoil';
import employerService, { employerState } from '../../Shared/services/EmployerService';
import auth, { userState } from '../../Shared/services/auth';
import { add } from 'ionicons/icons';
import NewAnnouncementModal from './components/NewAnnouncementModal';
import { AnnouncementComponent } from './components/announcement';
import AnnouncementAdminService, {
  AnnouncementAdminDto,
  AnnouncementListResult,
} from '../../Shared/services/AnnouncementAdminService';
import { activeAnnouncementDataState, editAnnouncementDataState, pastAnnouncementDataState } from './atoms';
import { AxiosResponse } from 'axios';
import EditAnnouncementModal from './components/EditAnnouncementModal';
import EmptyAnnouncementState from './EmptyAnnouncementState';

function AnnouncementsPage() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);
  const [selectedTab, setSelectedTab] = useState('active');
  const [activeAnnouncements, setActiveAnnouncements] = useRecoilState(activeAnnouncementDataState);
  const [pastAnnouncements, setPastAnnouncements] = useRecoilState(pastAnnouncementDataState);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [announcement, setAnnouncement] = useRecoilState(editAnnouncementDataState);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }
      setUser(res.data);
    });

    AnnouncementAdminService.GetAnnouncementsList().then(
      (res: AxiosResponse<AnnouncementListResult>) => {
        const now = new Date().setHours(0, 0, 0, 0); //set to midnight to ignore time
        const announcementData = res.data.announcements;
        const activeAnnouncements = announcementData.filter((x) => {
          if (!x.whenExpires) return true;

          const expiresDate = new Date(x.whenExpires).setHours(0, 0, 0, 0); //Set to midnight to ignore time
          return expiresDate >= now;
        });

        const pastAnnouncements = announcementData.filter((x) => {
          if (!x.whenExpires) return false;

          const expiresDate = new Date(x.whenExpires).setHours(0, 0, 0, 0); //Set to midnight to ignore time
          return expiresDate <= now;
        });
        //TODO - loading trickery
        setActiveAnnouncements(activeAnnouncements);
        setPastAnnouncements(pastAnnouncements);
      },
    );
  }, []);

  const handleNewAnnouncement = () => {
    setShowNewModal(true);
  };

  const handleEdit = (id: number) => {
    const selectedAnnouncement = activeAnnouncements.filter(aa => aa.id === id)[0];
    setAnnouncement(selectedAnnouncement);
    setShowEditModal(true);
  };

  const handleApprove = (id: number) => {
    const updatedAnnouncements = activeAnnouncements.map((a) => {
      if(a.id === id){
        a.status = 1; //set status to approved for the UI
        return a;
      } else {
        return a;
      }
    });
    setActiveAnnouncements(updatedAnnouncements);
    AnnouncementAdminService.ApproveAnnouncements([id])
      .then(() => console.log(`AnnouncementId ${id} Approved Successfully`))
  };

  const handleDelete = (id: number) => {
    const selectedAnnouncement = activeAnnouncements.filter(aa => aa.id === id)[0];
    AnnouncementAdminService.DeleteAnnouncement(selectedAnnouncement.id)
                            .then(() => {
                              console.log(`AnnouncementId ${selectedAnnouncement.id} Deleted Successfully`);
                              const remainingAnnouncements = activeAnnouncements.filter(aa => aa.id !== selectedAnnouncement.id);
                              setActiveAnnouncements(remainingAnnouncements);
                            });
  }

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <NavBar user={user} />
        <div style={{ flex: 1, marginTop: '40px', padding: '20px' }}>
          <IonContent fullscreen className='main-content'>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>Announcements</h3>

                <IonButton color='dark' style={{ fontSize: 16 }} onClick={handleNewAnnouncement}>
                  <IonIcon slot='icon-only' icon={add} />
                  New
                </IonButton>
              </div>
            </div>
            <div>
              <IonSegment
                value={selectedTab}
                onIonChange={(e) => setSelectedTab(e.detail.value as string)}
                style={{
                  width: '240px',
                }}
              >
                <IonSegmentButton value='active'>
                  <IonLabel>Active</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='past'>
                  <IonLabel>Past</IonLabel>
                </IonSegmentButton>
              </IonSegment>

              {selectedTab === 'active' && (
                activeAnnouncements.length > 0 ?
                <>
                  {activeAnnouncements.map((announcement) => (
                    <AnnouncementComponent
                      key={announcement.id}
                      announcement={announcement}
                      isActive={announcement.status >= 1}
                      isPresent={true}
                      onEdit={handleEdit}
                      onApprove={handleApprove}
                      onDelete={handleDelete}
                    />
                  ))}
                </>
                : <EmptyAnnouncementState/>
              )}

              {selectedTab === 'past' && (
                <>
                  {pastAnnouncements.map((announcement) => (
                    <AnnouncementComponent
                      key={announcement.id}
                      announcement={announcement}
                      isActive={false}
                      isPresent={false}
                      onEdit={(id:number) => console.log("attempting to edit a past announcement")}
                    />
                  ))}
                </>
              )}
            </div>
          </IonContent>
        </div>
      </div>
      {showNewModal && (
        <NewAnnouncementModal
          isOpen={showNewModal}
          onClose={() => setShowNewModal(false)}
          onCreateAnnouncement={(announcement) => {
            console.log(`Creating Announcement ${announcement}`);
            AnnouncementAdminService.AddAnnouncement(announcement)
              .then(() => setActiveAnnouncements((prev) => [announcement, ...prev]))
              .catch((e) => console.log(`Error from API: ${e}`));
          }}
        />
      )}
      {showEditModal && (
        <EditAnnouncementModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          onEditAnnouncement={(announcement) => {
            console.log(`Editing Announcement ${announcement}`);
            AnnouncementAdminService.UpdateAnnouncement(announcement)
              .then((ua) => {
                const updatedAnnouncements = activeAnnouncements.map(aa => {
                  if(aa.id === announcement.id){
                    return ua.data;
                  } else {
                    return aa;
                  }
                });
                setActiveAnnouncements(updatedAnnouncements);
              })
              .catch((e) => console.log(`Error from API: ${e}`));
          }}
        />
      )}
    </IonPage>
  );
}

export default AnnouncementsPage;
