import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

export interface UserTargetType {
  id: number;
  description: string;
}

export interface UserTargetId {
  id: string;
  name: string;
}

const utilityService = {
  async getUserTargetTypes() {
    return axios.get<UserTargetType[]>(`${APIURL}/Utility/GetUserTargetTypes`);
  },
  async getUserTargets(typeId: string) {
    return axios.get<UserTargetId[]>(`${APIURL}/Utility/GetUserTargets?userTargetType=${typeId}`);
  },
};

export default utilityService;
