import React, { useState, useRef, useEffect } from 'react';
import {
  IonModal,
  IonButton,
  IonInput,
  IonIcon,
  IonImg,
  IonContent,
  IonTextarea,
} from '@ionic/react';
import { camera } from 'ionicons/icons';
import moment from 'moment';
import AnnouncementAdminService, {
  AnnouncementAdminDto,
} from '../../../Shared/services/AnnouncementAdminService';
import { newAnnouncementDataState } from '../atoms';
import { useRecoilState } from 'recoil';
import UserTargetSelector from '../../../components/UserTargetSelector';
import DateSelector from '../../../components/DateSelector';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateAnnouncement: (announcement: AnnouncementAdminDto) => void;
}

const NewAnnouncementModal: React.FC<ModalProps> = ({ isOpen, onClose, onCreateAnnouncement }) => {
  const [announcement, setAnnouncement] = useRecoilState(newAnnouncementDataState);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    AnnouncementAdminService.GetNewAnnouncement()
      .then((newAnnResp) => setAnnouncement(newAnnResp.data))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleCreateAnnouncement = () => {
    setLoading(true);
    if (announcement !== null) {
      onCreateAnnouncement(announcement);
    }
    setLoading(false);
    onClose();
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLoading(true);
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('file', file);
      AnnouncementAdminService.UploadAnnouncementPicture(formData)
        .then((resp) => {
          setAnnouncement((a) => {
            if (a === null) return null;

            return {
              ...a,
              picture: resp.data,
            };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current?.click();
  };

  const labelPlacement = 'floating';

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className='mx-modal-narrow'>
      <IonContent>
        <div style={{ padding: 20 }}>
          <input
            type='file'
            accept='image/*'
            onChange={handleImageUpload}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            {imagePreview ? (
              <IonImg
                src={imagePreview}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: 10,
                  marginRight: 15,
                }}
              />
            ) : (
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f4f4f4',
                  cursor: 'pointer',
                  borderRadius: 10,
                  marginRight: 15,
                }}
                onClick={triggerImageUpload}
              >
                <IonIcon icon={camera} size='large' />
              </div>
            )}
            <IonButton
              onClick={triggerImageUpload}
              color='dark'
              style={{ flexGrow: 1, height: '44px' }}
            >
              {image ? 'Change Image' : 'Upload Image'}
            </IonButton>
          </div>
          <IonInput
            placeholder='Title'
            label='Title'
            labelPlacement={labelPlacement}
            value={announcement?.title}
            onIonChange={(e) => {
              const newTitle = e.detail.value ?? '';
              setAnnouncement((a) => {
                if (a === null) return null;
                return {
                  ...a,
                  title: newTitle,
                };
              });
            }}
            style={{
              width: '100%',
              border: '1px solid black',
              padding: 6,
              borderBottom: '2px solid black',
              borderRight: '2px solid black',
              borderRadius: 10,
              marginTop: 5,
            }}
          ></IonInput>
          <IonTextarea
            placeholder='Body'
            value={announcement?.body}
            label='Body'
            labelPlacement={labelPlacement}
            onIonChange={(e) => {
              const newDesc = e.detail.value ?? '';
              setAnnouncement((a) => {
                if (a === null) return null;
                return {
                  ...a,
                  body: newDesc,
                };
              });
            }}
            style={{
              width: '100%',
              border: '1px solid black',
              borderBottom: '2px solid black',
              borderRight: '2px solid black',
              borderRadius: 10,
              marginTop: 10,
              padding: '8px',
            }}
            rows={4}
            autoGrow={true}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              gap: 8,
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              padding: 4,
            }}
          >
            <div>
              Announcement Start Date
              <DateSelector
                id='startDate'
                value={announcement?.whenLive}
                onChange={(d) => 
                  setAnnouncement((a) => {
                    if(a === null) return null;
                    return {
                      ...a,
                      whenLive: d
                    }
                  })
                }
              />
            </div>
            <div>
              Announcement End Date
              <DateSelector
                id='endDate'
                value={announcement?.whenExpires}
                onChange={(d) => 
                  setAnnouncement((a) => {
                    if(a === null) return null;
                    return {
                      ...a,
                      whenExpires: d
                    }
                  })
                }
              />
            </div>
          </div>
          {announcement?.canUpdateEmployers && (
            <IonInput
              placeholder='Send To Employers'
              label='Employer Ids'
              labelPlacement={labelPlacement}
              value={announcement?.sendEmployers}
              onIonChange={(e) => {
                const newEmployerIds = e.detail.value ?? '';
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    sendEmployers: newEmployerIds,
                  };
                });
              }}
              style={{
                width: '100%',
                border: '1px solid black',
                padding: 6,
                borderBottom: '2px solid black',
                borderRight: '2px solid black',
                borderRadius: 10,
                marginTop: 5,
              }}
            ></IonInput>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              gap: 8,
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              padding: 4,
            }}
          >
            <IonInput
              placeholder='Link'
              label='Announcement Link'
              labelPlacement={labelPlacement}
              value={announcement?.link}
              type='url'
              onIonChange={(e) => {
                const newLink = e.detail.value ?? '';
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    link: newLink,
                  };
                });
              }}
              style={{
                width: '100%',
                border: '1px solid black',
                padding: 6,
                borderBottom: '2px solid black',
                borderRight: '2px solid black',
                borderRadius: 10,
                marginTop: 5,
              }}
            ></IonInput>
            <IonInput
              placeholder='Link Text'
              label='Announcement Link Text'
              labelPlacement={labelPlacement}
              value={announcement?.linkText}
              onIonChange={(e) => {
                const newLinkText = e.detail.value ?? '';
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    linkText: newLinkText,
                  };
                });
              }}
              style={{
                width: '100%',
                border: '1px solid black',
                padding: 6,
                borderBottom: '2px solid black',
                borderRight: '2px solid black',
                borderRadius: 10,
                marginTop: 5,
              }}
            ></IonInput>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              gap: 8,
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              padding: 4,
            }}
          >
            <IonInput
              placeholder='Video Link'
              label='Video Link'
              labelPlacement={labelPlacement}
              value={announcement?.videoLink}
              type='url'
              onIonChange={(e) => {
                const newVideoLink = e.detail.value ?? '';
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    videoLink: newVideoLink,
                  };
                });
              }}
              style={{
                width: '100%',
                border: '1px solid black',
                padding: 6,
                borderBottom: '2px solid black',
                borderRight: '2px solid black',
                borderRadius: 10,
                marginTop: 5,
              }}
            ></IonInput>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              gap: 8,
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              padding: 4,
            }}
          >
            <UserTargetSelector
              onTargetTypeChange={(e) => {
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    targetType: parseInt(e.target.value ?? '0'),
                  };
                });
              }}
              onTargetIdChange={(e) => {
                setAnnouncement((a) => {
                  if (a === null) return null;
                  return {
                    ...a,
                    targetIds: e.target.value.toString(),
                  };
                });
              }}
              selectorMessage='Would would you like to see this Announcement?'
            />
          </div>
          <IonButton
            onClick={handleCreateAnnouncement}
            expand='block'
            color='dark'
            style={{ marginTop: 20 }}
            disabled={loading}
          >
            Create Announcement
          </IonButton>
          <IonButton onClick={onClose} expand='block' color='light' style={{ marginTop: 10 }}>
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default NewAnnouncementModal;
