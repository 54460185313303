import React, { useState, useEffect } from 'react';
import utilityService, { UserTargetId, UserTargetType } from '../../Shared/services/UtilityService';

interface UserTargetProps {
  initialTargetType?: string | null;
  initialSelectedIds?: string | null;
  selectorMessage?: string | null;
  onTargetTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onTargetIdChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const UserTargetSelector = (props: UserTargetProps) => {
  const [targetTypes, setTargetTypes] = useState<UserTargetType[]>([]);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [targetIds, setTargetIds] = useState<UserTargetId[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    fetchTargetTypes();
    if (props.initialTargetType) {
      setSelectedType(props.initialTargetType);
    }
    if (props.initialSelectedIds) {
      setSelectedId(props.initialSelectedIds);
    }
  }, []);

  useEffect(() => {
    if (selectedType) {
      fetchTargetIds(selectedType);
    } else {
      setTargetIds([]);
      setSelectedId(null);
    }
  }, [selectedType]);

  const fetchTargetTypes = async () => {
    try {
      const response = await utilityService.getUserTargetTypes();
      setTargetTypes(response.data);
    } catch (error) {
      console.error('Error fetching user target types:', error);
    }
  };

  const fetchTargetIds = async (typeId: string) => {
    try {
      const response = await utilityService.getUserTargets(typeId);
      setTargetIds(response.data);
    } catch (error) {
      console.error('Error fetching user target IDs:', error);
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
    setSelectedId(null);
    props.onTargetTypeChange(event);
  };

  const handleIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(event.target.value);
    props.onTargetIdChange(event);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
      }}
    >
      <div>
        <label htmlFor='targetType'>Target Type</label>
        <select
          id='targetType'
          className='mx-border'
          value={selectedType || ''}
          onChange={handleTypeChange}
          style={{
            padding: 8,
          }}
        >
          <option value='' disabled>
            { props.selectorMessage ?? 'Select target type...' }
          </option>
          {targetTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.description}
            </option>
          ))}
        </select>
      </div>

      {/** No reason to show TargetId if selectedType === 'Everyone' */}
      {selectedType && selectedType !== '1' && (
        <div>
          <label htmlFor='targetId'>Target ID</label>
          <select
            id='targetId'
            className='mx-border'
            value={selectedId || ''}
            onChange={handleIdChange}
            style={{
              padding: 8,
            }}
          >
            <option value='' disabled>
              Select targets...
            </option>
            {targetIds.map((id) => (
              <option key={id.id} value={id.id}>
                {id.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default UserTargetSelector;
