import React from 'react';

import './EmptyAnnouncementState.css';

const EmptyAnnouncementState = () => {

return (

<div className="empty-state-container">

  <h2>There are no current announcements.</h2>

  <p>

    To create a new announcement, simply click the ‘New’ button at the top right side of this page.

  </p>

  <p>

    Your announcements will be visible during the selected date range after they are approved.

  </p>

  <p>

    Send us a message at <a href="mailto:stephen.enke@maxwell.app">stephen.enke@maxwell.app</a> if you have any questions!

  </p>

</div>

);

};

export default EmptyAnnouncementState;