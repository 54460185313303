import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonSearchbar,
  IonSpinner,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import NavBar from '../../Shared/NavBar';

import { useRecoilState } from 'recoil';
import WalletService from '../../Shared/services/WalletService';
import employerService, { employerState } from '../../Shared/services/EmployerService';
import teamService, { TeamCount } from '../../Shared/services/TeamService';
import auth, { userState } from '../../Shared/services/auth';
import NewMemberModal from '../NewMemberModal';
import SideBar from '../../Shared/SideBar';
import { DataGrid } from '@mui/x-data-grid';
import MemberModal from '../MemberModal';
import Skeleton from 'react-loading-skeleton';
import BulkModals from '../BulkModals';
import { useHistory } from 'react-router';
import '../style.css';
import { columns } from './columns';
import {
  bulkModalAtom,
  confirmApproveAtom,
  selectedRowsAtom,
  teamDataAtom,
  teamQueryAtom,
} from './atoms';
import { Toolbar } from './toolbar';
import { NumberBadge } from './shared';
import AcceptTerms from '../../Home/AcceptTerm';

function Team_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);
  const [controlGroups, setControlGroups] = useState<any>(null);
  const [budgets, setBudgets] = useState<any>(null);
  const [regalosTags, setRegalosTags] = useState<any>([]);
  const [loadingTeamPage, setLoadingTeamPage] = useState<boolean>(false);
  const [loadingTeamSearch, setLoadingTeamSearch] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [member, setMember] = useState<any>(null);
  const [showBulkModal, setShowBulkModal] = useRecoilState(bulkModalAtom);
  const [selectedRows, setSelectedRows] = useRecoilState(selectedRowsAtom);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [confirmApprove, setConfirmApprove] = useRecoilState<boolean>(confirmApproveAtom);

  const [teamData, setTeamData] = useRecoilState(teamDataAtom);
  const [teamQuery, setTeamQuery] = useRecoilState(teamQueryAtom);
  const [teamCountData, setTeamCountData] = useState<TeamCount>();

  useEffect(() => {
    console.log('Running with teamQuery', teamQuery);
    setLoadingTeamPage(true);
    teamService
      .allTeamMembers(teamQuery)
      .then((res) => {
        setTeamData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingTeamSearch(false);
        setLoadingTeamPage(false);
      });
  }, [teamQuery]);

  useEffect(() => {
    console.log('Loading TeamCounts');
    teamService
      .teamCount()
      .then((res) => {
        setTeamCountData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const history = useHistory();

  const bulkediting = localStorage.getItem('bulkedit') == 'true';

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      setUser(res.data);
      employerService.GetEmployer().then((emp: any) => {
        setEmployer(emp.data);
      });
      WalletService.GetWallets().then((budgetRes: any) => {
        console.log('budget', budgetRes);
        setBudgets(budgetRes);
      });

      teamService.controlGroups().then((controlRes: any) => {
        console.log('control', controlRes.data);
        setControlGroups(controlRes.data);
      });

      //RegalosTags
      teamService.regalosTags().then((res: any) => {
        console.log('regalos', res.data);
        setRegalosTags(res.data);
      });
    });
  }, []);

  function refetchTeam() {
    setTeamQuery((prev) => ({
      ...prev,
    }));
  }

  return (
    <IonPage>
      {/** AcceptTerms page is temporarily here, until we rebuild the home page properly */}
      {employer && <AcceptTerms employer={employer} />}

      {teamData && teamData.members && (
        <BulkModals
          open={showBulkModal}
          onClose={() => setShowBulkModal('false')}
          memberIds={selectedRows}
          employer={employer}
          controlGroups={controlGroups}
          team={teamData.members}
          budgets={budgets}
          regalosTags={regalosTags}
          member={selectedMember}
          onReload={refetchTeam}
        />
      )}
      <IonAlert
        isOpen={confirmApprove}
        onDidDismiss={() => setConfirmApprove(false)}
        header={`Approve ${selectedRows.length} Members?`}
        message='Are you sure you want to approve selected members?'
        buttons={[
          {
            text: 'Yes',
            role: 'yes',
            handler: () => {
              for (const m of selectedRows) {
                teamService.approveMember(m);
              }
            },
          },
          {
            text: 'No',
            role: 'no',
          },
        ]}
      />
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 20 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingTop: 18, flex: 1 }}>
                  <div>Hello, {user.firstName}! Below is...</div>
                  <div className='page-title'>Your Team</div>
                </div>
                <div style={{ paddingTop: 50 }}>
                  <div style={{ display: 'flex' }}>
                    {bulkediting && (
                      <div>
                        <IonButton
                          color='dark'
                          onClick={() => {
                            history.push('/import');
                          }}
                        >
                          Import Members
                        </IonButton>
                      </div>
                    )}
                    <div style={{ flex: 1 }}>
                      {teamData && controlGroups && (
                        <NewMemberModal
                          team={teamData.members}
                          controlGroups={controlGroups}
                          reload={refetchTeam}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <IonGrid className='ion-no-padding'>
                <>
                  <IonCol size='12' style={{ paddingTop: 22 }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ marginBottom: 4 }}>
                          <IonSearchbar
                            className='filter-search'
                            debounce={300}
                            placeholder='Search by name, email, or EEID'
                            style={{
                              width: 240,
                              border: '1px solid black',
                              padding: 6,
                              borderBottom: '2px solid black',
                              borderRight: '2px solid black',
                              borderRadius: 10,
                              marginTop: 5,
                            }}
                            onIonInput={(e) => {
                              setLoadingTeamSearch(true);
                              setTeamQuery((prev) => ({
                                ...prev,
                                search: e.detail.value || '',
                              }));
                            }}
                          />
                        </div>

                        <div style={{ padding: 8, marginBottom: -12 }}>
                          {loadingTeamSearch && <IonSpinner name='lines' />}
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <IonButton
                          color={teamQuery.onboardingStatus == 'all' ? 'dark' : 'light'}
                          size='small'
                          onClick={() => {
                            setTeamQuery((prev) => ({
                              ...prev,
                              onboardingStatus: 'all',
                            }));
                          }}
                          style={{ marginLeft: 12 }}
                        >
                          {teamCountData && <NumberBadge value={teamCountData.all} />} Show All
                        </IonButton>
                        <IonButton
                          color={teamQuery.onboardingStatus === 'notOnboarded' ? 'dark' : 'light'}
                          size='small'
                          onClick={() => {
                            setTeamQuery((prev) => ({
                              ...prev,
                              onboardingStatus: 'notOnboarded',
                            }));
                          }}
                          style={{ marginLeft: 12 }}
                        >
                          {teamCountData && <NumberBadge value={teamCountData.notOnboarded} />} Not
                          Onboarded
                        </IonButton>
                        <IonButton
                          size='small'
                          color={teamQuery.onboardingStatus === 'onboarded' ? 'dark' : 'light'}
                          onClick={() => {
                            setTeamQuery((prev) => ({
                              ...prev,
                              onboardingStatus: 'onboarded',
                            }));
                          }}
                          style={{ marginLeft: 12 }}
                        >
                          {teamCountData && <NumberBadge value={teamCountData.onboarded} />}{' '}
                          Onboarded{' '}
                        </IonButton>
                        <IonButton
                          size='small'
                          color={teamQuery.onboardingStatus === 'pending' ? 'dark' : 'light'}
                          onClick={() => {
                            setTeamQuery((prev) => ({
                              ...prev,
                              onboardingStatus: 'pending',
                            }));
                          }}
                          style={{ marginLeft: 12 }}
                        >
                          {teamCountData && <NumberBadge value={teamCountData.pending} />} Pending
                        </IonButton>
                      </div>
                    </div>
                  </IonCol>
                  {teamData ? (
                    <DataGrid
                      className='rubix'
                      checkboxSelection
                      pageSizeOptions={[10, 25, 50, 100]}
                      onCellClick={(e) => {
                        if (e.field !== '__check__') {
                          setShowModal(true);
                          setMember(e.row);
                        }
                      }}
                      onFilterModelChange={(e) => {
                        const filter = e.items[0];

                        if (filter?.value) {
                          setTeamQuery((prev) => ({
                            ...prev,
                            filterField: filter.field,
                            filterValue: filter.value,
                            filterOperator: filter.operator,
                          }));
                        } else {
                          setTeamQuery((prev) => ({
                            ...prev,
                            filterField: '',
                            filterValue: '',
                            filterOperator: '',
                          }));
                        }
                      }}
                      onRowSelectionModelChange={(e: any) => {
                        setSelectedRows(e);
                        if (e && e[0] && e.length == 1) {
                          const mID: number = parseInt(e[0]);
                          teamService.member(mID).then((res: any) => {
                            console.log('member', res.data);
                            setSelectedMember(res.data);
                          });
                        } else {
                          setSelectedMember(null);
                        }
                      }}
                      filterMode='server'
                      sx={{
                        border: 'solid black 1px',
                        borderRadius: 2,
                        color: 'black',
                        '& .MuiDataGrid-cell': {
                          color: 'black',
                          fontSize: 16,
                          fontWeight: 400,
                        },
                        '& .MuiButton-root': {
                          color: 'black !important',
                        },
                        '& .MuiTouchRipple-root': {
                          color: 'black !important',
                        },
                        '& .MuiButton': {
                          color: 'black',
                        },
                      }}
                      slots={{
                        toolbar: Toolbar,
                      }}
                      rows={teamData.members}
                      columns={columns}
                      loading={loadingTeamPage}
                      paginationMode='server'
                      rowCount={teamData.total}
                      onPaginationModelChange={(e) => {
                        setTeamQuery((prev) => ({
                          ...prev,
                          page: e.page,
                          pageSize: e.pageSize,
                        }));
                      }}
                      sortingMode='server'
                      onSortModelChange={(e) => {
                        setTeamQuery((prev) => ({
                          ...prev,
                          sortField: e?.[0].field || '',
                          sortOrder: e?.[0].sort || '',
                        }));
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            page: teamQuery.page,
                            pageSize: teamQuery.pageSize,
                          },
                        },
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns
                            id: false,
                            workLocation: false,
                            department: false,
                            jobTitle: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Skeleton style={{ border: 2, borderRadius: 10 }} height={400} />
                  )}
                </>
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
      {showModal && member && (
        <MemberModal
          bulkediting
          regalosTags={regalosTags}
          controlGroups={controlGroups}
          member={member}
          showModal={showModal}
          employer={employer}
          budgets={budgets}
          dismissed={() => {
            setShowModal(false);
          }}
          editMember={() => {
            setShowModal(false);
            setSelectedRows([member.id]);
            teamService.member(member.id).then((res: any) => {
              console.log('member', res.data);
              setMember(res.data);
              setSelectedMember(res.data);
              setShowBulkModal('editModal');
            });
          }}
        />
      )}
    </IonPage>
  );
}

export default Team_Page;
